import cn from 'classnames';
import HeadingWithTooltip from '../../../../../features/heading-with-tooltip/HeadingWithTooltip';
import RuleCell from '../../../../../features/rule-cell/RuleCell';
import styles from './OverlapsBox.module.scss';
import { useAccount } from '@azure/msal-react';
import { useFormState } from '../../../../../data/form-state/useFormState';

import { Box, FormRow, Grid, GridCol, Legend, List, Spinner, Text } from '@flixbus/honeycomb-react';
import React, { useMemo } from 'react';

// Tooltip with information about overlaps
const TOOLTIP = (
    <>
        <List>
            <li key="overlap-tooltip-list-1">
                <Text small extraClasses={styles.tooltipText}>
                    Two rules overlap if they have at least one common city pair, departure date and PBP.
                </Text>
            </li>
            <li key="overlap-tooltip-list-2">
                <Text small extraClasses={styles.tooltipText}>
                    If two influences overlap, the resulting alpha is calculated using the expression (1 + alpha1/100) *
                    (1 + alpha2/100) - 1.
                </Text>
            </li>
        </List>
    </>
);

const OverlapsBox = () => {
    // Get form context state
    const { overlappingRules, setOverlappingRules, overlapsDataLoading, refreshVisData } = useFormState();

    const account = useAccount();
    // TODO: Refactor this to minimize props drilling
    const updateRuleActiveStatus = (ruleId: number, newActiveStatus: boolean) => {
        setOverlappingRules(
            overlappingRules.map((rule) =>
                rule.id == ruleId
                    ? {
                          ...rule,
                          active: newActiveStatus,
                          lastModifiedAt: new Date(),
                          lastModifiedBy: account ? account.username : '',
                      }
                    : rule,
            ),
        );
        refreshVisData();
    };

    // Split active from user deactivated rules
    const activeOverlappingRules = useMemo(
        () => overlappingRules.filter((rule) => rule.active === true),
        [overlappingRules],
    );
    const deactivatedOverlappingRules = useMemo(
        () => overlappingRules.filter((rule) => rule.active === false),
        [overlappingRules],
    );

    return (
        <Box extraClasses={styles.box}>
            <Grid>
                <GridCol size={12} extraClasses={styles.headingRow}>
                    <HeadingWithTooltip title="Overlapping Rules" tooltip={TOOLTIP} tooltipSize="large" />
                </GridCol>
                {overlapsDataLoading ? (
                    <GridCol size={12} extraClasses={styles.spinnerRow}>
                        <Spinner size="md" />
                    </GridCol>
                ) : (
                    <GridCol size={12}>
                        {overlappingRules.length ? (
                            <Grid>
                                <GridCol size={12}>
                                    {activeOverlappingRules.map((rule, index) => (
                                        <FormRow
                                            extraClasses={cn(styles.row, {
                                                [styles.firstRow]: index === 0,
                                                [styles.lastRow]:
                                                    index + 1 === activeOverlappingRules.length &&
                                                    deactivatedOverlappingRules.length === 0,
                                            })}
                                            key={`from-row-${index}`}
                                        >
                                            <RuleCell
                                                key={`rule-cell-${rule.id}`}
                                                rule={rule}
                                                updateRuleActiveStatus={updateRuleActiveStatus}
                                            />
                                        </FormRow>
                                    ))}
                                </GridCol>
                                {deactivatedOverlappingRules.length !== 0 && (
                                    <GridCol size={12}>
                                        <Legend>Deactivated rules</Legend>
                                    </GridCol>
                                )}
                                <GridCol size={12}>
                                    {deactivatedOverlappingRules.map((rule, index) => (
                                        <FormRow
                                            extraClasses={cn(styles.row, {
                                                [styles.lastRow]: index + 1 === deactivatedOverlappingRules.length,
                                            })}
                                            key={`from-row-${index}`}
                                        >
                                            <RuleCell
                                                key={`rule-cell-${rule.id}`}
                                                rule={rule}
                                                updateRuleActiveStatus={updateRuleActiveStatus}
                                            />
                                        </FormRow>
                                    ))}
                                </GridCol>
                            </Grid>
                        ) : (
                            'There are no overlapping rules.'
                        )}
                    </GridCol>
                )}
            </Grid>
        </Box>
    );
};

export default OverlapsBox;
