import FilterHub from './components/filter-hub/FilterHub';
import { isFilterRangeContained } from '../../utils/date.utils';
import RulesGrid from './components/rules-grid/RulesGrid';
import RulesGridSkeleton from './components/rules-grid/RulesGridSkeleton';
import styles from './RuleList.module.scss';
import { useDebounce } from '../../utils/hooks/useDebounce';
import { useFilterData } from '../../data/filter-data/useFilterData';
import { useFormState } from '../../data/form-state/useFormState';
import { useRules } from '../../data/rules-data/ruleData.context';

import { AutocompleteOptionType, MainWrapper, PageContainer, Text } from '@flixbus/honeycomb-react';
import { cityPairArrayToString, cityPairStringToArray } from '../../utils/cityPairs.utils';
import { DateRange, RuleData } from '../../types';
import React, { useEffect, useMemo, useState } from 'react';

const RuleList = () => {
    const { rulesDataAndState, updateRuleActiveStatus } = useRules();

    // Initialize rules data state (to be filtered and sorted)
    const [displayRules, setDisplayRules] = useState<RuleData[]>([]);

    // Intialize filter state
    const [search, setSearch] = useState<string>('');
    const [loadingSearch, setLoadingSearch] = useState<boolean>(false);
    const [tags, setTags] = useState<string[]>([]);
    const [fromCities, setFromCities] = useState<string[]>([]);
    const [toCities, setToCities] = useState<string[]>([]);
    const [dateRange, setDateRange] = useState<DateRange | undefined>(undefined);
    const [lastModifiers, setLastModifiers] = useState<string[]>([]);
    const [active, setActive] = useState<boolean>(true);
    const [archieved, setArchieved] = useState<boolean>(false);

    // Handle active state list behaviour
    const rulesData: RuleData[] = useMemo(() => {
        return active ? rulesDataAndState.active.data : rulesDataAndState.inactive.data;
    }, [active, rulesDataAndState.active.data, rulesDataAndState.inactive.data]);

    // Set autocompletion data for authors
    const modifiersData: AutocompleteOptionType[] = useMemo(
        () =>
            Array.from(new Set(rulesData.map((rule: RuleData) => rule.lastModifiedBy)))
                .sort((a: string, b: string) => a.localeCompare(b))
                .map((modifier) => ({ title: modifier as string })),
        [rulesData],
    );

    // Search & Filter functionality
    // Note this is a first naïve implementation, if it is to slow once there
    // is a lot of rules data, this might be re-implemented in backend!!
    const debouncedSearch = useDebounce<string>(search, '', 250);
    useEffect(() => {
        let filteredRules = rulesData;
        // Filter based on active
        filteredRules = filteredRules.filter((rule: RuleData) => rule.active === active);
        // Filter based on archieved
        filteredRules = filteredRules.filter((rule: RuleData) => rule.archieved === archieved);
        // Free search on title, tags and cities
        if (debouncedSearch !== '') {
            const searchLowered = debouncedSearch.toLowerCase();
            filteredRules = filteredRules.filter(
                (rule: RuleData) =>
                    rule.title.toLowerCase().includes(searchLowered) ||
                    rule.tags.some((tag) => tag.toLowerCase().includes(searchLowered)) ||
                    rule.cityPairs.some((cityPair) =>
                        cityPairStringToArray(cityPair)[0].toLowerCase().includes(searchLowered),
                    ) ||
                    rule.cityPairs.some((cityPair) =>
                        cityPairStringToArray(cityPair)[1].toLowerCase().includes(searchLowered),
                    ) ||
                    rule.lastModifiedBy.toLowerCase().includes(searchLowered),
            );
        }
        setLoadingSearch(false);
        // Search based on tags filter
        if (tags.length !== 0) {
            tags.forEach((tag) => {
                filteredRules = filteredRules.filter((rule: RuleData) => rule.tags.includes(tag));
            });
        }
        // Search based on departure cities filter
        if (fromCities.length !== 0) {
            filteredRules = filteredRules.filter((rule: RuleData) =>
                rule.cityPairs.some((cityPair) => fromCities.includes(cityPairStringToArray(cityPair)[0])),
            );
        }
        // Search based on arrival cities filter
        if (toCities.length !== 0) {
            filteredRules = filteredRules.filter((rule: RuleData) =>
                rule.cityPairs.some((cityPair) => toCities.includes(cityPairStringToArray(cityPair)[1])),
            );
        }
        // Search based on departure dates
        if (dateRange) {
            filteredRules = filteredRules.filter((rule: RuleData) =>
                isFilterRangeContained(dateRange, rule.includedDates, rule.excludedDates, rule.daysOfTheWeek),
            );
        }
        // Search based on last modifiers filter
        if (lastModifiers.length !== 0) {
            filteredRules = filteredRules.filter((rule: RuleData) => lastModifiers.includes(rule.lastModifiedBy));
        }

        setDisplayRules(filteredRules);
    }, [debouncedSearch, tags, fromCities, toCities, dateRange, lastModifiers, active, archieved, rulesData]);

    // Clear all filters and fetch active rules
    const onClearFilters = () => {
        // setTitle('');
        setTags([]);
        setFromCities([]);
        setToCities([]);
        setDateRange(undefined);
        setLastModifiers([]);
        // setActive(true);
        setArchieved(false);
        setDisplayRules(rulesData);
    };

    // Prepare function to initialize form while cloning rules
    // TODO: useMemo?
    const { initializeForm } = useFormState();
    const { getCityPairUUIDs } = useFilterData();
    const initializeCloneForm = (ruleId: number) => {
        const ruleData = rulesData.filter((data) => data.id === ruleId)[0];
        initializeForm(
            undefined,
            getCityPairUUIDs(ruleData.cityPairs).map((cityPair) => cityPairArrayToString(cityPair)),
            ruleData.includedDates,
            ruleData.excludedDates,
            ruleData.daysOfTheWeek,
            ruleData.daysBeforeDeparture,
            ruleData.alpha,
            ruleData.title + '_CLONED',
            ruleData.tags,
            ruleData.active,
        );
    };

    // Prepare function to initialize form while editing rules
    const initializeEditForm = (ruleId: number) => {
        const ruleData = rulesData.filter((data) => data.id === ruleId)[0];
        initializeForm(
            ruleData.id,
            getCityPairUUIDs(ruleData.cityPairs).map((cityPair) => cityPairArrayToString(cityPair)),
            ruleData.includedDates,
            ruleData.excludedDates,
            ruleData.daysOfTheWeek,
            ruleData.daysBeforeDeparture,
            ruleData.alpha,
            ruleData.title,
            ruleData.tags,
            ruleData.active,
        );
    };

    return (
        <>
            <MainWrapper>
                <PageContainer>
                    <FilterHub
                        search={search}
                        onSearchChange={(e: any) => {
                            setLoadingSearch(true);
                            setSearch(e);
                        }}
                        loadingSearch={loadingSearch}
                        tags={tags}
                        onTagsChange={setTags}
                        fromCities={fromCities}
                        onFromCitiesChange={setFromCities}
                        toCities={toCities}
                        onToCitiesChange={setToCities}
                        dateRange={dateRange}
                        onDateRangeChange={setDateRange}
                        lastModifiers={lastModifiers}
                        onLastModifiersChange={setLastModifiers}
                        active={active}
                        onActiveChange={setActive}
                        archieved={archieved}
                        onArchievedChange={setArchieved}
                        modifiersData={modifiersData}
                        onClearFilters={onClearFilters}
                    />
                </PageContainer>
            </MainWrapper>
            {/* Additional Wrapper to be able to change
          background color to secondary */}
            <MainWrapper full extraClasses={styles.rulesGridContainer}>
                <PageContainer>
                    {(active && rulesDataAndState.active.loading) || (!active && rulesDataAndState.inactive.loading) ? (
                        <RulesGridSkeleton />
                    ) : displayRules.length ? (
                        <RulesGrid
                            data={displayRules}
                            updateRuleActiveStatus={updateRuleActiveStatus}
                            initializeCloneForm={initializeCloneForm}
                            initializeEditForm={initializeEditForm}
                        />
                    ) : (
                        <Text extraClasses={styles.noRulestText}>There are no rules matching the filter criteria.</Text>
                    )}
                </PageContainer>
            </MainWrapper>
        </>
    );
};

export default RuleList;
