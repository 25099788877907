import ActionsDropdown from './components/ActionsDropdown';
import AlphaPill from '../alpha-pill/alphaPill';
import CityPairsTable from '../city-pairs-table/CityPairsTable';
import cn from 'classnames';
import DaysOfTheWeekInput from '../days-of-the-week-input/DaysOfTheWeekInput';
import React from 'react';
import { RuleData } from '../../types';
import styles from './RuleCell.module.scss';

import { Accordion, Divider, Grid, GridCol, Legend, Tag, Text, Tooltip } from '@flixbus/honeycomb-react';
import { dateRangesToString, dateToDEString, departuresLength } from '../../utils/date.utils';
import { Icon, IconArrowBigRight } from '@flixbus/honeycomb-icons-react';

type Props = {
    rule: RuleData;
    updateRuleActiveStatus?: (ruleId: number, newActiveStatus: boolean) => void;
    initializeCloneForm?: (ruleId: number) => void;
    initializeEditForm?: (ruleId: number) => void;
    compact?: boolean;
};

const RuleCell: React.FC<Props> = ({
    rule,
    updateRuleActiveStatus,
    initializeCloneForm,
    initializeEditForm,
    compact = false,
}) => {
    // Unpack rule
    const {
        id,
        title,
        tags,
        active,
        archieved,
        cityPairs,
        includedDates,
        excludedDates,
        daysOfTheWeek,
        daysBeforeDeparture,
        alpha,
        createdAt,
        lastModifiedAt,
        lastModifiedBy,
    } = rule;

    // Format data
    const includedDeparturesString = dateRangesToString(includedDates);
    const excludedDeparturesString = dateRangesToString(excludedDates);
    const formatedCreatedAt = dateToDEString(createdAt);
    const formatedUpdatedAt = dateToDEString(lastModifiedAt);
    const activeText = active ? 'Active rule' : 'Inactive Rule';
    const daysBeforeDepartureString = daysBeforeDeparture ? (
        <>
            {daysBeforeDeparture[0]} <Icon InlineIcon={IconArrowBigRight} size={4} /> {daysBeforeDeparture[1]} days
        </>
    ) : (
        ''
    );
    const lenCityPairs = cityPairs.length;
    const durantion = departuresLength(includedDates, excludedDates, daysOfTheWeek);

    return compact ? (
        <Accordion
            extraClasses={styles.accordion}
            title={
                (
                    <Grid align="center">
                        <GridCol key="title-info" size={8}>
                            <div className={styles.titleContainer}>
                                <div className={styles.titleLink}>
                                    <Tooltip
                                        id="statusIndicator"
                                        content={activeText}
                                        position="left"
                                        size="content-fit"
                                        openOnHover
                                        smartPosition
                                    >
                                        <div
                                            className={cn(styles.statusIndicator, {
                                                [styles.activeIndicator]: active,
                                            })}
                                        />
                                    </Tooltip>
                                    <Tooltip id="title" content={title} position="top" size="content-fit" openOnHover>
                                        <div
                                            className={cn(styles.title, {
                                                [styles.short]: true,
                                            })}
                                        >
                                            {title}
                                        </div>
                                    </Tooltip>
                                </div>
                            </div>
                            {archieved && (
                                <Tag extraClasses={styles.tag} small appearance="warning">
                                    Archieved
                                </Tag>
                            )}
                            {tags.map((tag) => (
                                <Tag key={`tag-${tag}-rule-${id}`} small extraClasses={styles.tag}>
                                    {tag}
                                </Tag>
                            ))}
                        </GridCol>
                        <GridCol size={4}>
                            <Grid align="center">
                                <GridCol key="days-info" size={6}>
                                    <Legend>{daysBeforeDepartureString}</Legend>
                                </GridCol>
                                <GridCol key="alpha-info" size={6}>
                                    <AlphaPill alpha={alpha} />
                                </GridCol>
                            </Grid>
                        </GridCol>
                    </Grid>
                ) as any
            }
        >
            <>
                <Divider extraClasses={styles.mainDivider} />
                <Grid align="top">
                    <GridCol key="city-pairs-info" size={12}>
                        <Legend>City pairs</Legend>
                        <CityPairsTable cityPairsData={cityPairs}></CityPairsTable>
                    </GridCol>
                    <GridCol key="divider-1" size={12}>
                        <Divider extraClasses={styles.summaryDivider} />
                    </GridCol>
                    <GridCol key="included-departures-info" size={6} extraClasses={styles.row}>
                        <Legend>Included departures</Legend>
                        {includedDeparturesString.map((dateRange) => (
                            <Text key={`included-date-${dateRange}`} extraClasses={styles.dateRangeText}>
                                {dateRange}
                            </Text>
                        ))}
                    </GridCol>
                    <GridCol key="excluded-departures-info" size={6} extraClasses={styles.row}>
                        <Legend>Excluded departures</Legend>
                        {excludedDeparturesString.map((dateRange) => (
                            <Text key={`excluded-date-${dateRange}`} extraClasses={styles.dateRangeText}>
                                {dateRange}
                            </Text>
                        ))}
                    </GridCol>
                    <GridCol key="days-of-week-info" size={12}>
                        <Legend>Weekdays</Legend>
                        <DaysOfTheWeekInput daysOfTheWeek={daysOfTheWeek} />
                    </GridCol>
                    <GridCol key="divider-2" size={12}>
                        <Divider extraClasses={styles.summaryDivider} />
                    </GridCol>
                    <GridCol key="created-at-info" size={6}>
                        <Legend>Created</Legend>
                        <Text>{formatedCreatedAt}</Text>
                    </GridCol>
                    <GridCol key="last-update-info" size={6}>
                        <Legend>Last Updated</Legend>
                        <Text>
                            {formatedUpdatedAt} by {lastModifiedBy}
                        </Text>
                    </GridCol>
                </Grid>
            </>
        </Accordion>
    ) : (
        <Accordion
            extraClasses={styles.accordion}
            title={
                (
                    <Grid align="center">
                        <GridCol key="title-info" size={6}>
                            <div className={styles.titleContainer}>
                                <div className={styles.titleLink}>
                                    <Tooltip
                                        id="statusIndicator"
                                        content={activeText}
                                        position="left"
                                        size="content-fit"
                                        openOnHover
                                        smartPosition
                                    >
                                        <div
                                            className={cn(styles.statusIndicator, {
                                                [styles.activeIndicator]: active,
                                            })}
                                        />
                                    </Tooltip>
                                    <Tooltip id="title" content={title} position="top" size="content-fit" openOnHover>
                                        <div className={styles.title}>{title}</div>
                                    </Tooltip>
                                </div>
                            </div>
                            {archieved && (
                                <Tag extraClasses={styles.tag} small appearance="warning">
                                    Archieved
                                </Tag>
                            )}
                            {tags.map((tag) => (
                                <Tag key={`tag-${tag}-rule-${id}`} small extraClasses={styles.tag}>
                                    {tag}
                                </Tag>
                            ))}
                        </GridCol>
                        <GridCol size={6}>
                            <Grid>
                                <GridCol key="city-pairs-length-info" size={3}>
                                    <Legend>{lenCityPairs} city pairs</Legend>
                                </GridCol>
                                <GridCol key="durantion-info" size={3}>
                                    <Legend>{durantion} dates</Legend>
                                </GridCol>
                                <GridCol key="days-info" size={3}>
                                    <Legend>{daysBeforeDepartureString}</Legend>
                                </GridCol>
                                <GridCol key="alpha-info" size={2}>
                                    <AlphaPill alpha={alpha} />
                                </GridCol>
                                {!archieved && updateRuleActiveStatus !== undefined && (
                                    <GridCol size={1}>
                                        <ActionsDropdown
                                            ruleId={id}
                                            title={title}
                                            activeStatus={active}
                                            updateRuleActiveStatus={updateRuleActiveStatus}
                                            initializeCloneForm={initializeCloneForm}
                                            initializeEditForm={initializeEditForm}
                                        />
                                    </GridCol>
                                )}
                            </Grid>
                        </GridCol>
                    </Grid>
                ) as any
            }
        >
            <>
                <Divider extraClasses={styles.mainDivider} />
                <Grid align="top" gutterSize="4">
                    <GridCol key="city-pairs-info" size={6} extraClasses={styles.row}>
                        <Legend>City Pairs</Legend>
                        <CityPairsTable cityPairsData={cityPairs}></CityPairsTable>
                    </GridCol>
                    <GridCol key="included-departures-info" size={6}>
                        <Grid align="top">
                            <GridCol size={5} extraClasses={styles.row}>
                                <Legend>Included Departures</Legend>
                                {includedDeparturesString.map((dateRange) => (
                                    <Text key={`included-date-${dateRange}`} extraClasses={styles.dateRangeText}>
                                        {dateRange}
                                    </Text>
                                ))}
                            </GridCol>
                            <GridCol size={7} extraClasses={styles.row}>
                                <Legend>{'Excluded departures'}</Legend>
                                {excludedDeparturesString.map((dateRange) => (
                                    <Text key={`excluded-date-${dateRange}`} extraClasses={styles.dateRangeText}>
                                        {dateRange}
                                    </Text>
                                ))}
                            </GridCol>
                            <GridCol size={12} extraClasses={styles.row}>
                                <Legend>Weekdays</Legend>
                                <DaysOfTheWeekInput daysOfTheWeek={daysOfTheWeek} />
                            </GridCol>
                            <GridCol key="created-at-info" size={5}>
                                <Legend>Created</Legend>
                                <Text>{formatedCreatedAt}</Text>
                            </GridCol>
                            <GridCol key="last-update-info" size={7}>
                                <Legend>Last Updated</Legend>
                                <Text>
                                    {formatedUpdatedAt} by {lastModifiedBy}
                                </Text>
                            </GridCol>
                        </Grid>
                    </GridCol>
                </Grid>
            </>
        </Accordion>
    );
};

export default RuleCell;
